import React, { useEffect } from "react";
import { ModalDiv } from "../styled/Modal";
import CriarCarteiraMobile from "./criarCarteiraMobile";

const Modal = ({ modalVisivel, setModalVisivel, currentStep, setCurrentStep}) => {
  useEffect(() => {
    if (modalVisivel) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => document.body.classList.remove("modal-open");
  }, [modalVisivel]);

  return (
    <>
      {modalVisivel ? (
        <ModalDiv className="ModalDiv">
{  currentStep === 1 && <div className="div_header">
            <img className="logoHeader" src={"./logo_unisc.svg"} alt="logo" />
          </div>}
          <div className="div_container">
            <CriarCarteiraMobile setModalVisivel={setModalVisivel} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
          </div>
        </ModalDiv>
      ) : null}
    </>
  );
};

export default Modal;
