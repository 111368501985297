import React, { useEffect, useRef } from "react";
import { DivGlobal } from "../styled/DownloadCarteira";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { QRCodeCanvas } from "qrcode.react";
import moment from "moment-timezone";
const { apiUrl } = require("../services/requests");

const DownloadCarteiras = ({ carteira, setComponenteRenderizado }) => {
  const ref = useRef(null);
  const url_atual = window.location.href;
  const carteiraAntiga = "31/03/2025";
  const carteiraAtual = moment(carteira.dataValidade)
    .utcOffset("-0000")
    .format("DD/MM/YYYY");
  const picture = () => {
    const node = document.getElementById("download_carteira");

    html2canvas(node, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const base64URl = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      saveAs(base64URl, `${carteira.codigoUso}.jpeg`);
    });
  };

  useEffect(() => {
      picture();
      setComponenteRenderizado(false);
  }, []);

  return (
    <DivGlobal
      ref={ref}
      id="download_carteira"
      isFundoAzul={carteiraAtual > carteiraAntiga}
    >
      <div className="logos">
        <img className="dne" src={`${apiUrl}/images/DNE.png`} alt="dne" />
        {carteiraAtual > carteiraAntiga ? (
          <div>
            <img
              className="logo_unisc_2025"
              src={`${apiUrl}/images/2025.png`}
              alt="logo_unisc"
            />
          </div>
        ) : (
          <div className="logos_unisc">
            <img
              className="logo_unisc"
              src={`${apiUrl}/images/logo_unisc.png`}
              alt="logo_unisc"
            />
            <img
              className="logo_2024"
              src={`${apiUrl}/images/2024.png`}
              alt="logo_2024"
            />
          </div>
        )}
      </div>
      <div className="foto_qr">
        <img src={carteira.foto} className="foto_aluno" alt="foto_aluno" />
        <div className="qr_cod">
          <div className="editQr">
            <QRCodeCanvas
              className="qrCode"
              value={url_atual}
              includeMargin={true}
              size={135}
            />
          </div>
          <div className="codUso">
            <span> COD. DE USO</span>
            <span className="codigoUso"> {carteira.codigoUso}</span>
          </div>
        </div>
      </div>
      <div className="dadosEstudante" isFundoAzul={carteiraAtual > carteiraAntiga}>
        <div className="student_name">
          <span className="title">Nome:</span>
          <span>{carteira.name}</span>
        </div>
        <div className="student_class">
          <span className="title">Instituição de ensino:</span>
          <span>{carteira.instituicaoEnsino}</span>
        </div>
        <div className="student_course">
          <span className="title">Curso:</span>
          <span>{carteira.curso}</span>
        </div>
        <p className="student_documents">Documentos:</p>
        <div className="student_cpf">
          <span className="title">CPF:</span>
          <span>{carteira.cpf}</span>
        </div>
        <div className="student_rg">
          <span className="title">RG:</span>
          <span>{carteira.rg}</span>
        </div>
        <div className="student_birth">
          <span className="title">Data Nasc: </span>
          <span>{carteira.dataNascimento}</span>
        </div>
      </div>
      <div className="icon_verify">
        <img
          className="verificado"
          src={
            carteiraAntiga < carteiraAtual
              ? `${apiUrl}/images/verificado_2025.png`
              : `${apiUrl}/images/verificado.png`
          }
          alt="verificado"
        />
      </div>
    </DivGlobal>
  );
};

export default DownloadCarteiras;

//verificado.png
