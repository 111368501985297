import styled from "styled-components";
import { CloseOutline } from "@styled-icons/evaicons-outline";

export const ModalDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3b71da;
  display: flex;
  align-items: center;
  z-index: 1;
  touch-action: none;
  flex-direction: column;

  .div_header {
    position: relative;
    width: 100%;
    top: -50px;
    height: 30px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1000" height="300"><ellipse cx="500" cy="5" rx="500" ry="250" fill="white"/></svg>') no-repeat center center;
    background-size: auto;
    padding: 100px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-height: 700px) {
      top: -100px;
    }
  }

  .div_container {
    position: relative; 
    background-color: #3b71da;
    z-index: 0; 
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    top: 0;

    @media screen and (max-height: 700px) {
      top: -100px;
    }

    .fechar {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-size: 20px;
      background-color: #65a140;
      color: white;
      font-family: "League Gothic", sans-serif;
      border: none;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      margin-bottom: 5px;
      width: 100px;
    }
  }

  .logoHeader {
    max-width: 100%;
    height: auto;

    @media screen and (max-height: 700px) {
      position: relative;
      top: 50px;
    }
  }
`;
