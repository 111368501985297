import React, { useState, useEffect } from "react";
import {
  DivCarteira
} from "../styled/CriarCarteira";
import EnviarImagem from "./EnviarImagem";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { cpfMask } from "../services/funcoesAuxiliares";
import InputMask from "react-input-mask";
const { criarCarteiras, uploadImage, apiUrl } = require("../services/requests");

const CriarCarteira = ({getCarteiras}) => {
  const date = new Date();
  const newDate = moment(date).locale("pt-br").format("YYYY-MM-DD HH:mm:ss");
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    data: { id },
  } = jwtDecode(user);

  const AnoMes = moment().format("YYMM");
  var random2 = "",
    d = 0;
  while (d < 3) {
    random2 += Math.floor(Math.random() * 10);
    d++;
  }
  var random1 = "",
    c = 0;
  while (c < 3) {
    random1 += Math.floor(Math.random() * 10);
    c++;
  }
  const codUso = `${AnoMes}-${random1}.${random2}`;

  const [nome, setNome] = useState("");
  const [instituicaoEnsino, setInstituicaoEnsino] = useState("");
  const [curso, setCurso] = useState("");
  const [imagemInicial] = useState(`${apiUrl}/images/0.png`);
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [imagem, setImagem] = useState("");
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const criarCarteira = async (event) => {
    event.preventDefault();

    if (isButtonDisabled || isSubmitting) return;
    setIsSubmitting(true);

    try {
      const endpoint = "/carteiras";
      const upload = "/upload-image";

      if (imagem !== "") {
        const formData = new FormData();
        formData.append("image", imagem);

        await uploadImage(upload, formData);
      }

      await criarCarteiras(endpoint, {
        foto: imagem === "" ? imagemInicial : `${apiUrl}/images/${imagem.name}`,
        codigoUso: codUso,
        nome,
        instituicaoEnsino,
        curso,
        cpf,
        rg,
        dataNascimento,
        dataValidade,
        dataEmissao: newDate,
        user_id: id,
        email,
        telefone,
        observacao: "",
      });

      getCarteiras();
      setError(false);
      alert("Carteira Criada Com Sucesso!");
      setNome("");
      setInstituicaoEnsino("");
      setCurso("");
      setCpf("");
      setRg("");
      setDataNascimento("");
      setEmail("");
      setTelefone("");
    } catch (error) {
      if (error.request.status === 409) {
        setError(true);
      }
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const formData = [nome, instituicaoEnsino, curso, cpf, rg, dataNascimento];

    const isValidFormData = formData.every((value) => value !== "");
    setIsButtonDisabled(!isValidFormData);
  }, [nome, instituicaoEnsino, curso, cpf, rg, dataNascimento]);

  return (
    <DivCarteira>
      <div className="container_imagem">
        <EnviarImagem img={imagem} setImg={setImagem} />
      </div>
      <div className="container_dados">
        <input
          value={nome}
          id="nome"
          type="text"
          placeholder="NOME"
          onChange={(e) => setNome(e.target.value.toUpperCase())}
        />
        <input
          id="InstEnsino"
          placeholder="INSTITUIÇÃO DE ENSINO"
          value={instituicaoEnsino}
          type="text"
          onChange={(e) => setInstituicaoEnsino(e.target.value.toUpperCase())}
        />
        <input
          id="Curso"
          placeholder="CURSO"
          value={curso}
          type="text"
          onChange={(e) => setCurso(e.target.value.toUpperCase())}
        />
        <div className="linha">
          <input
            id="cpf"
            placeholder="CPF"
            value={cpf}
            type="text"
            onChange={(e) => setCpf(cpfMask(e.target.value))}
          />
        </div>
        <div className="linha">
          <input
            id="RG"
            placeholder="RG"
            type="text"
            value={rg}
            onChange={(e) => setRg(e.target.value)}
          />

          <InputMask
            mask="99/99/9999"
            id="DataNascimento"
            placeholder="DATA DE NASCIMENTO"
            type="text"
            value={dataNascimento}
            onChange={(e) => setDataNascimento(e.target.value)}
          />
        </div>
      </div>
      <div className="buttons">
        <a
          className="checar"
          href="https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp"
          target="_blank"
          rel="noreferrer"
        >
          CPF
        </a>
        {error ? <p>{"CPF existente"}</p> : null}
        <button
          type="submit"
          className="confirmar"
          disabled={isButtonDisabled || isSubmitting}
          onClick={(event) => criarCarteira(event)}
        >
          CONFIRMAR
        </button>
      </div>
    </DivCarteira>
  );
};

export default CriarCarteira;
