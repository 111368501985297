import styled from "styled-components";

export const DivCentral = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .div_carteiras {
      background-color: #aac6fc;
      border-radius: 15px;
      margin-bottom: 10px;
      width: 100%;
      padding: 10px;

      .div_foto {
        display: flex;
        align-items: center;

        .carteira_img {
          display: flex;
          align-items: center;
          justify-content: center;

          .inputEditarImagem {
              z-index: 1;
              position: relative;
              opacity: 0;
              width: 120px;
              height: 150px;
              left: 50px;
              cursor: pointer;

              &:disabled {
                cursor: not-allowed;
                pointer-events: none;
              }
            }

            .editarImagem {
              position: relative;
              z-index: 0;
              width: 60%;
              height: 100%;
              border-radius: 10px;
              left: -70px;
            }
        }

      


        #status_carteira {
          padding: 10px;
          margin: 10px;
          height: 50px;
          border: none;
          background-color: white;
          font-size: 18px;
          font-weight: bold;
          font-family: "Fira Sans", sans-serif;

          :disabled {
            background-color: white;
          }
        }
      }

      .header_carteiras {
        .div_dados {
          display: flex;
          flex-direction: column;
          padding: 10px;

          .dados_input {
            margin: 3px;
            padding: 5px;
            border: none;
            font-weight: 700;
            border-radius: 5px;
          }
        }

        .linha {
          display: flex;
          width: 100%;
          font-size: small;
          font-weight: 700;

          .dados_label {
            margin: 5px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            span {
              margin-right: 5px;
            }

            .input_label {
              font-weight: 700;
              width: 100%;
              padding: 5px;
              border: none;
              border-radius: 5px;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-around;

      .copiar_url {
        background-color: #ecae04;
        font-family: "League Gothic", sans-serif;
        font-size: 1.5em;
        border: none;
        color: white;
        width: 30%;
        padding: 5px;
        border-radius: 7px;
        cursor: pointer;
      }

      .visualizar {
        text-align: center;
        background-color: #3e4095;
        text-decoration: none;
        font-family: "League Gothic", sans-serif;
        font-size: 1.5em;
        border: none;
        color: white;
        padding: 5px;
        width: 30%;
        border-radius: 7px;
      }

      .btn_renovacao {
        text-align: center;
        background-color: #ec0404;
        color: white;
        text-decoration: none;
        font-family: "League Gothic", sans-serif;
        font-size: 1.5em;
        border: none;
        padding: 5px;
        width: 30%;
        border-radius: 7px;
      }
    }
  }
`;
