import styled from "styled-components";
import { MultiSelect } from "react-multi-select-component";
import { Filter } from "@styled-icons/fa-solid";
import { Eraser } from "@styled-icons/fa-solid";

export const FiltroIcon = styled(Filter)`
  max-width: 15px;
`;

export const Borracha = styled(Eraser)`
  max-width: 20px;
`;

export const Section = styled.section`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #aac6fc;
  position: relative;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 1190px) {
    .container {
      .form_dados {
        display: grid;
        grid-template-columns: repeat(4, 2fr);
        grid-template-rows: auto auto;

        .div_nome {
     
        }

        .div_dados {

        }

        .div_status {

        }

        .div_data {
   

          input {
            width: 180px;
          }
        }

        .div_gestores {
          width: 100%;
          margin-left: 5px;

        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    form {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .div_nome {
        width: 20%;
        input {
          border: none;
          border-radius: 5px;
          margin: 5px;
          padding: 10px;
        }
      }

      .div_dados {
        width: 20%;
        position: relative;
        input {
          border: none;
          border-radius: 5px;
          margin: 5px;
          padding: 10px;
        }
      }

      .div_status {
      }

      .div_data {
        max-width: 20%;

        input {
          border: none;
          width: 80%;
          margin: 5px;
          padding: 10px;
          border-radius: 5px;
        }
      }

      .div_gestores {
        input {
          border: none;
          margin: 5px;
        }
      }
    }
  }

  .ButtonsFiltro {
    padding: 0 5% 0 5%;
    border-left: 3px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .botaoFiltrar {
    border-radius: 5px;
    font-size: 20px;
    padding: 5px;
    width: 80px;
    display: flex;
    margin: 10px;
    color: white;
    background-color: #65a140;
    font-family: "League Gothic", sans-serif;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    border: none;
  }

  .botaoLimparFiltro {
    border-radius: 5px;
    padding: 5px;
    font-size: 20px;
    width: 80px;
    display: flex;
    margin: 10px;
    color: white;
    font-family: "League Gothic", sans-serif;
    background-color: #ecae04;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    border: none;
    white-space: nowrap;
  }
`;

export const SelectStatus = styled(MultiSelect)`
  width: 150px;

  @media screen and (max-width: 1190px){
    width: 197px;
  }

  .dropdown-heading-value .gray {
    color: #000000;
    font-size: 16px; /* Ajusta o tamanho da fonte */
  }
`;

export const SelectGestores = styled(MultiSelect)`
  width: 150px;


  @media screen and (max-width: 1190px){
    width: 197px;
  }
  .dropdown-heading-value .gray {
    color: #000000;
    font-size: 16px; /* Ajusta o tamanho da fonte */
  }
`;

export const ContainerRelevanciaNome = styled.div`
  max-height: 200px;
  width: 300px;
  padding-left: 5px;
  position: absolute;
  z-index: 1;
  box-shadow: -1px 1px 10px black;
  top: 95px;
  left: 22px;
  background-color: #fbfbfb;
  overflow: hidden;
  overflow-y: auto;

  .itens {
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
  }
`;

export const ContainerRelevanciaCodUso = styled.div`
  max-height: 200px;
  width: 300px;
  position: absolute;
  left: 5px;
  padding-left: 5px;
  box-shadow: -1px 1px 10px black;
  z-index: 1;
  background-color: #fbfbfb;
  overflow: hidden;
  overflow-y: auto;

  .itens {
    display: flex;
    margin-top: 10px;
    align-items: center;
    cursor: pointer;
    gap: 12px;
  }
`;
