import styled from "styled-components";
import { CheckDouble } from "@styled-icons/boxicons-regular";
import { Enter } from "@styled-icons/ionicons-solid";

export const DivCarteira = styled.div`
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  background-color: #aac6fc;

  .container_imagem {
    max-width: 140px;
    max-height: 180px;
    border-radius: 15px;
    margin-top: 30px;

    .inputImage {
      z-index: 1;
      position: relative;
      opacity: 0;
      width: 100%;
      height: 130px;
    }

    .image {
      z-index: 0;
      margin-top: -160px;
      min-width: 100%;
      min-height: 100%;
      border-radius: 10px;
    }
  }

  .container_dados {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: -20px 40px 0px 10px;

    #nome {
      margin: 0 5px 5px 5px;
      padding: 5px;
      border-radius: 5px;
      border: none;
      font-weight: 700;
    }

    #InstEnsino {
      margin: 0 5px 5px 5px;
      padding: 5px;
      border-radius: 5px;
      border: none;
      font-weight: 700;
    }

    #Curso {
      margin: 0 5px 5px 5px;
      padding: 5px;
      border-radius: 5px;
      border: none;
      font-weight: 700;
    }

    .linha {
      display: flex;
      width: 100%;
      font-size: small;
      font-weight: 700;

      input {
        font-weight: 700;
        width: 100%;
        margin: 5px;
        padding: 5px;
        border: none;
        border-radius: 5px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;

    .confirmar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-size: 20px;
      background-color: #3e4095;
      color: white;
      font-family: "League Gothic", sans-serif;
      border: none;
      text-align: center;
      box-sizing: border-box;
      margin-bottom: 5px;
      width: 100px;
    }

    .checar {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-size: 20px;
      background-color: #65a140;
      color: white;
      font-family: "League Gothic", sans-serif;
      border: none;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      margin-bottom: 5px;
      width: 100px;
    }
  }
`;
