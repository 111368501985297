import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  HeaderContainer,
  ButtonLogout,
  ButtonUser,
  Principal,
  IconPanel,
  ListaIcon
} from "../styled/header";
import { jwtDecode } from "jwt-decode";

const { apiUrl } = require("../services/requests");

const Header = (props) => {
  const { location, toggleLayout } = props;

  const user = JSON.parse(localStorage.getItem("user"));
  const {
    data: { id, role, name },
  } = jwtDecode(user);

  const navigate = useNavigate();
  const painelUsuario = () => navigate(`/user/${id}`);

  const Loggout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const site = () => navigate("/");
  const painel = () => navigate("/painel");
  const gestores = () => navigate("/gestores");

  return (
    <>
      <HeaderContainer>
        <div className="logo">
          <div className="logo_border">
            <img className="logoHeader" src={"./logo_unisc.svg"} alt="logo" />
          </div>
        </div>
        <div className="logged_user">
          <h4>{name}</h4>
          </div>
        <div className="buttons">
          <button 
          className="button_lista"
          onClick={toggleLayout}
          >
            <ListaIcon/>
            <span>LISTA</span>
          </button>
          <button className="button_site" onClick={() => site()}>
            <Principal />
            <span>SITE</span>
          </button>
          {role === "administrador" && location !== "gestores" ? (
            <button className="button_gestao" onClick={() => gestores()}>
              <Principal />
              <span>GESTÃO</span>
            </button>
          ) : null}
          {location === "gestores" && (
            <button className="button_painel" onClick={painel}>
              <IconPanel />
              <span> VENDAS </span>
            </button>
          )}
          <button
            className="button_user"
            type="submit"
            onClick={() => painelUsuario()}
          >
            <ButtonUser />
            <span> USUARIO </span>
          </button>
          <button
            className="button_logout"
            type="submit"
            onClick={() => Loggout()}
          >
            <ButtonLogout />
            <span>SAIR</span>
          </button>
        </div>
      </HeaderContainer>
    </>
  );
};

export default Header;
