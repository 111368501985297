import Pagination from '../componentes/Pagination/Pagination';
import styled from "styled-components";

export const Page = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: center;


  @media screen and (max-width: 1000px){
    width: 70%;
  } 

  @media screen and (max-width: 400px){
    padding: 5px;
    margin-left: 50px;
    width: 50%;
  } 
  `;
