import React, { useState } from "react";
import FiltrarDados from "../componentes/filtrarDados";
import Carteiras from "../componentes/carteiras";
import Header from "../componentes/header";
import FiltrarDadosMobile from "../componentes/filtroMobile";

const Painel = () => {
  const [filtro, setFiltro] = useState([]);
  const [error, setError] = useState(false);
  const [isSingleColumn, setIsSingleColumn] = useState(() => {
    const savedLayout = localStorage.getItem("isSingleColumn");
    return savedLayout === "true";
  });  const largura = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const [currentStep, setCurrentStep] = useState(1);


  const toggleLayout = () => {
    setIsSingleColumn((prev) => {
      const newLayout = !prev;
      localStorage.setItem("isSingleColumn", newLayout);
      return newLayout;
    });
  };
  return (
    <>
      <Header toggleLayout={toggleLayout} currentStep={currentStep}/>
     { largura >= 1000 ?
      <FiltrarDados setFiltro={setFiltro} setError={setError} filtro={filtro} /> :
      <FiltrarDadosMobile setFiltro={setFiltro} setError={setError} filtro={filtro} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
     }
      <Carteiras isSingleColumn={isSingleColumn} error={error} filtro={filtro}  />
    </>
  )
}

export default Painel;