import React, { useState, useEffect, useCallback, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import {
  SelectStatus,
  SelectGestores,
  Section,
  ContainerRelevanciaCodUso,
  ContainerRelevanciaNome,
} from "../styled/FiltrarDados";
import { requestData } from "../services/requests";
import { cpfMask } from "../services/funcoesAuxiliares";

const FiltrarDados = ({ setError, setFiltro }) => {
  const arrayStatus = [
    { label: "Recepcionada", value: "Recepcionada" },
    { label: "Validada", value: "Validada" },
    { label: "Renovação", value: "Renovacao" },
    { label: "Expirada", value: "Expirada" },
    { label: "Cancelada", value: "Cancelada" },
  ];

  const hoje = moment().format().split("T")[0];
  const mesPassado = moment().subtract(1, "month").format();
  const MesAnterior = mesPassado.split("T")[0];

  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = jwtDecode(user);
  const codUsoRef = useRef(null);
  const nomeAlunoRef = useRef(null);
  const [carteiras, setCarteiras] = useState([]);
  const [filterSeach, setFilterSearch] = useState([]);
  const [filterSearchAluno, setFilterSearchAluno] = useState([]);
  const [users, setUsers] = useState([]);
  const [nomeAluno, setNomeAluno] = useState("");
  const [codigoUso, setCodigoUso] = useState("");
  const [gestor, setGestor] = useState([
    { label: data.name, value: data.name, id: data.id },
  ]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [dataInicial, setDataInicial] = useState(MesAnterior);
  const [dataFinal, setDataFinal] = useState(hoje);
  const [cpf, setCpf] = useState("");

  const handleChange = (value) => {
    const unique = carteiras.filter(function (a) {
      return (
        !this[JSON.stringify(a.codigoUso)] &&
        (this[JSON.stringify(a.codigoUso)] = true)
      );
    }, Object.create(null));
    setCodigoUso(value);
    const x = value.substring(2);
    const newFilter = unique.filter((e) => {
      if (x === "") {
        return 0;
      } else {
        return e.codigoUso.includes(x);
      }
    });
    setFilterSearch(newFilter);
  };

  const handleChangeAluno = (value) => {
    const unique = carteiras.filter(function (a) {
      return (
        !this[JSON.stringify(a.cpf)] && (this[JSON.stringify(a.cpf)] = true)
      );
    }, Object.create(null));
    setNomeAluno(value);
    const x = value.substring(2);
    const newFilter = unique.filter((e) => {
      if (x === "") {
        return 0;
      }
      return e.name.includes(value);
    });
    setFilterSearchAluno(newFilter);
  };

  const handleClickCodigoUso = (value) => {
    setCodigoUso(value.codigoUso);
    setFilterSearch([]);
  };

  const handleClickAluno = (value) => {
    setNomeAluno(value.name);
    setFilterSearchAluno([]);
  };

  const gestores = async () => {
    const endpoint = "/user";
    const gestores = await requestData(endpoint);
    setUsers(gestores);
  };

  const getCarteiras = useCallback(async () => {
    const carteirasGestor = await requestData(
      `/carteiras/gestor/?id=${data.id}`
    );
    setCarteiras(carteirasGestor);
  }, [data.id, setCarteiras]);

  const newArrayGestores = users
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    .map((user) => ({ label: user.name, value: user.name, id: user.id }));

  const filtrar = async (e) => {
    e.preventDefault();
    try {
      const filter = {
        nomeAluno,
        codigoUso,
        gestor: gestor.map((c) => c.id),
        selectedStatus: selectedStatus.map((e) => e.value),
        dataInicial,
        dataFinal,
        cpf,
      };

      const filtroGestores = await requestData(
        `/carteiras/filtro?name=${filter.nomeAluno}&codigoUso=${filter.codigoUso}&gestor=${filter.gestor}&selectedStatus=${filter.selectedStatus}&dataInicial=${filter.dataInicial}&dataFinal=${filter.dataFinal}&cpf=${cpf}`
      );
      setFiltro(filtroGestores);
      setError(false);
    } catch (error) {
      setError(true);
    }
  };

  const limparFiltro = async (e) => {
    e.preventDefault();

    setNomeAluno("");
    setCodigoUso("");
    setGestor([{ label: data.name, value: data.name, id: data.id }]);
    setSelectedStatus([]);
    setDataInicial(MesAnterior);
    setDataFinal(hoje);
    setFiltro([]);
    setCpf("");
    setError(false);
  };

  const close = () => {
    if (nomeAluno.length < 3) {
      setFilterSearchAluno([]);
      setNomeAluno("");
    }

    if (codigoUso.length < 3) {
      setFilterSearch([]);
      setCodigoUso("");
    }
  };

  useEffect(() => {
    if (codigoUso === "") {
      setFilterSearch([]);
    }

    if (nomeAluno === "") {
      setFilterSearchAluno([]);
    }

    gestores();
  }, [codigoUso, nomeAluno]);

  useEffect(() => {
    getCarteiras();
  }, [getCarteiras]);

  return (
    <Section>
      <div className="container">
        <form className="form_dados">
          <div className="div_nome">
            <input
              onBlur={() => close()}
              ref={nomeAlunoRef}
              id="nomeAluno"
              type="text"
              value={nomeAluno}
              onChange={({ target: { value } }) =>
                handleChangeAluno(value.toUpperCase())
              }
              placeholder="NOME"
            />
            <ContainerRelevanciaNome>
              {filterSearchAluno.length !== 0 &&
                filterSearchAluno.map((e) => (
                  <div
                    className="itens"
                    key={e.id}
                    onClick={() => handleClickAluno(e)}
                  >
                    {e.name}
                  </div>
                ))}
            </ContainerRelevanciaNome>{" "}
          </div>
          <div className="div_dados">
            {" "}
            <input
              ref={codUsoRef}
              onBlur={() => close()}
              mask="9999-999.999"
              type="text"
              id="CodUso"
              value={codigoUso}
              onChange={({ target: { value } }) => handleChange(value)}
              placeholder="CODIGO DE USO"
            />
            <ContainerRelevanciaCodUso>
              {filterSeach.length !== 0 &&
                filterSeach.map((e) => (
                  <div
                    className="itens"
                    key={e.id}
                    onClick={() => handleClickCodigoUso(e)}
                  >
                    {e.codigoUso}
                  </div>
                ))}
            </ContainerRelevanciaCodUso>
            <input
              type="text"
              id="cpf"
              value={cpf}
              onChange={({ target: { value } }) => setCpf(cpfMask(value))}
              placeholder="CPF"
            />
          </div>
          <div className="div_status">
            {" "}
            <SelectStatus
              id="status"
              overrideStrings={{
                allItemsAreSelected: "Todos os Itens Selecionados",
                clearSearch: "Limpar Filtro",
                clearSelected: "Limpar Seleção",
                noOptions: "Sem opções",
                search: "Pesquisar",
                selectAll: "Selecionar Todos",
                selectAllFiltered: "Selecionar Todos",
                selectSomeItems: "Status",
                create: "Create",
              }}
              options={arrayStatus}
              onChange={setSelectedStatus}
              value={selectedStatus}
              labelledBy="Status"
              ClearSelectedIcon={null}
            />
          </div>
          <div className="div_data">
            <input
              id="dataInicial"
              type="date"
              value={dataInicial}
              onChange={({ target: { value } }) => setDataInicial(value)}
            />
            <input
              id="dataFinal"
              type="date"
              value={dataFinal}
              onChange={({ target: { value } }) => setDataFinal(value)}
            />
          </div>
          <div className="div_gestores">
            {" "}
            {data.role === "administrador" ? (
              <SelectGestores
                id="gestores"
                overrideStrings={{
                  allItemsAreSelected: "Todos os Itens Selecionados",
                  clearSearch: "Limpar Filtro",
                  clearSelected: "Limpar Seleção",
                  noOptions: "Sem opções",
                  search: "Pesquisar",
                  selectAll: "Selecionar Todos",
                  selectAllFiltered: "Selecionar Todos",
                  selectSomeItems: "Status",
                  create: "Create",
                }}
                options={newArrayGestores}
                value={gestor}
                onChange={setGestor}
                labelledBy="Select"
                ClearSelectedIcon={null}
              />
            ) : null}{" "}
          </div>
        </form>
        <div className="ButtonsFiltro">
          <button
            className="botaoLimparFiltro"
            onClick={(e) => limparFiltro(e)}
          >
            LIMPAR
          </button>
          <button className="botaoFiltrar" onClick={(e) => filtrar(e)}>
            FILTRAR
          </button>
        </div>
      </div>
    </Section>
  );
};

export default FiltrarDados;
