import styled from "styled-components";
import { PanelBottom } from "@styled-icons/fluentui-system-filled";
import { Person } from "@styled-icons/open-iconic/Person";
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { GlobeAlt } from "@styled-icons/heroicons-outline/GlobeAlt";
import {WifiProtectedSetup} from '@styled-icons/material-outlined/WifiProtectedSetup'


export const ListaIcon = styled(WifiProtectedSetup)`
 width: 30px;
  height: 30px;
  color: #000000;
  z-index: 1;`;


export const Principal = styled(GlobeAlt)`
  width: 30px;
  height: 30px;
  color: #000000;
  z-index: 1;
`;

export const IconPanel = styled(PanelBottom)`
  width: 30px;
  height: 30px;
  color: #000000;
  background-color: white;

`;

export const ButtonLogout = styled(LogOut)`
  width: 30px;
  height: 30px;
  color: #000000;
  z-index: 1;
  background-color: #ffffff;

`;

export const ButtonUser = styled(Person)`
  width: 25px;
  height: 25px;
  color: #000000;
  z-index: 1;
  background-color: white;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #3b71da;
  height: 60px;

  @media screen and (max-width: 1000px) {
    height: 70px;
    width: 100%;
  }

  .logo {
    border-radius: 5px;
    background-color: #000000;

    .logo_border {
      z-index: 1;
      position: absolute;
      left: 15px;
      top: 10px;
      background-color: #ffffff;
      width: 200px;
      height: 80px;
      border-radius: 50px;
      align-self: flex-start;

      @media screen and (max-width: 1000px) {
        top: 5px;
        width: 130px;
        height: 60px;
        position: absolute;

        .logoHeader {
          width: 100px;
        }
      }

      .logoHeader {
        z-index: 1;
        position: absolute;
        left: 15px;
        top: 10px;
        max-width: 160px;
        align-self: flex-start;
      }
    }
  }

  .logged_user {
    align-self: center;
    justify-self: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "League Gothic", sans-serif;
    font-size: 2em;
    color: #ffffff;

    @media screen and (max-width: 1000px) {
        display: none;
      }
  }

  .buttons {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: flex-end;

    .button_lista {


      margin-right: 5px;
      background-color: #ffffff;
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
      font-family: "League Gothic", sans-serif;
      font-size: 1.4em;
      span {
        background-color: white;
        color: black;
        margin-left: 5px;
      }

      @media screen and (max-width: 1000px) {
        display: none;
      }
  

    }
    
    .button_site {
      margin-right: 5px;
      background-color: #ffffff;
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
      font-family: "League Gothic", sans-serif;
      font-size: 1.4em;
      span {
        background-color: white;
        color: black;
        margin-left: 5px;
      }

      @media screen and (max-width: 1000px) {
        display: none;
      }
    }

    .button_gestao {
      margin-right: 5px;
      background-color: #ffffff;
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
      font-family: "League Gothic", sans-serif;
      font-size: 1.4em;
      span {
        z-index: 0;
        background-color: white;
        color: black;
        margin-left: 5px;
      }

      @media screen and (max-width: 1000px) {
        display: none;
      }
    }

    .button_painel {
      margin-right: 5px;
      background-color: #ffffff;
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
      font-family: "League Gothic", sans-serif;
      font-size: 1.4em;
      span {
        background-color: white;
        color: black;
        margin-left: 5px;
      }

      @media screen and (max-width: 1000px) {
        display: none;
      }
    }

    .button_user {
      margin-right: 5px;
      background-color: #ffffff;
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
      font-family: "League Gothic", sans-serif;
      font-size: 1.4em;
      span {
        background-color: white;
        color: black;
        margin-left: 5px;
      }

      @media screen and (max-width: 1000px) {
        display: none;
      }
    }

    .button_logout {
      margin-right: 5px;
      background-color: #ffffff;
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
    font-size: 1.4em;
      font-family: "League Gothic", sans-serif;

      span {
        background-color: white;
        color: black;
        margin-left: 5px;
      }
    }
  }
`;
