import styled from 'styled-components';
import { Linkedin, Facebook, Twitter, Instagram } from '@styled-icons/boxicons-logos';
import { Menu } from '@styled-icons/boxicons-regular';

export const MenuIcon = styled(Menu)`
width: 35px;
margin-left: 10px;
`;

export const LinkedinIcon = styled(Linkedin)`
color: white;
width: 20px;
margin-left: 10px;
margin-right: 10px;
`;

export const FacebookIcon = styled(Facebook)`
color: white;
width: 20px;
margin-left: 10px;
`;
export const TwitterIcon = styled(Twitter)`
color: white;
width: 20px;
margin-left: 10px;
`;
export const InstagramIcon = styled(Instagram)`
color: white;
width: 20px;
margin-left: 10px;
`;

export const Header = styled.div`
  display: flex;
  background-color: #0f3b8a;
  align-items: center;
  justify-content: flex-end;
  padding: 0.7em;
  color: white;
  font-family: 'Dosis', sans-serif;
  font-size: 12.5px;
  font-weight: 600;
  mix-blend-mode: difference;
`;


export const DivGlobal = styled.div`
  background-color: #f4f7f9;

  @media (max-width: 600px) {
    overflow: auto;
    overflow-y: visible;
    overflow-x: visible;
  }

.AtalhosDoSite {
  background-color: #f4f7f9;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Logo {
    img {
      max-width: 250px;
    }
  }

  @media (max-width: 700px) {
    .Logo {
      img {
        width: 150px;
      }
    }
    
  }

  .openMenu {
    border: 1px solid;
    position: relative;
    right: 0px;
  }

  .OpcoesMenu { 
    background-color: #0f3b8a;
    position: absolute;
    top: 85px;
    right: 0px;
    z-index: 1;

    @media (max-width: 500px) {
      right: 2px;
      top: 88px;
      display: flex;
      font-size: 0.6rem;
    }

    .Menu {
      display: flex;
      z-index: 1;
      a {
        margin: 5px;
        color: white;
        text-decoration: none;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }

  .Opcoes {
    display: flex;
    flex-wrap: wrap;
    a {
      color: #0f3b8a;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
      margin: 0px 20px 0px 0px;
      text-decoration: none;
    }
  }
}

.container_logo {
    background: url('https://api.uniscestudantil.org.br/images/fundo_pagina_inicial.jpg') no-repeat center center local;
    background-size: cover;

    .container_info {
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
    z-index: 0;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }  

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(135deg, rgba(93, 224, 230, 0.7) 0%, rgba(0, 74, 173, 0.7) 100%) 0px 0px;
      z-index: -1;
    }

    .texto {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 70%;
      width: 55%;
      text-align: center;
      flex-direction: column;

      @media (max-width: 600px) {
      margin-top: 20px;
      width: 100%;
    }  

      h1 {
        font-family: 'Arial Nova Cond', sans-serif;
        font-size: 6vw;
        font-weight: 700;
        color: #ffffff;
        margin: 0;

        @media (max-width: 600px) {
          max-width: 100%;
          font-size: 1.5em;
    }  
      }

      h2 
      {
        white-space: pre-line;
        max-width: 60%;
        font-family: 'Arial Nova Cond', sans-serif;
        text-align:  center;
        font-size: 2vw;
        font-weight: 700;
        color: #ffffff;

        @media (max-width: 600px) {
          max-width: 70%;
          font-size: 1em;
    }  
      }

      .consulta {
        background-color: rgb(255, 139, 55);
        width:  40%;
        height: 50px;
        border: none;
        border-radius: 10px;
        color: white;

        @media (max-width: 600px) {
          width: 150px;
          height: 30px;
          font-size: 0.8rem;
        }  
      }
    }

    .img {
      display: flex;
      align-self: flex-end;
      align-items: flex-end;
      margin: 0;
      padding: 0;
      justify-content: flex-start;
      max-width: 45%;
      flex: 1;

       img {
        max-width: 100%;
      }

      @media (max-width: 600px) {
        align-self: center;
        max-width: 100%;
      }
    }
  }
}

.carteiras_estudantis {
width: 100%;
background: url('https://api.uniscestudantil.org.br/images/fundo_segunda_pagina.png') no-repeat center center local;
background-size: cover;
display: flex;
flex-direction: column;
align-items: center;

.container_carteiras {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  justify-content: center;

  @media (max-width: 600px) {
        width: 100%;
        flex-direction: column;
        align-self: center;
      }
  

  .container_img {
    width: 60%;
    margin-top: 5%;
    z-index: 0;

    @media (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }

    img {
      max-width: 100%;
    }  
  }

  .container_texto {
  justify-content: center;
  display: flex;
  text-align: center;
  max-width: 100%;
  min-height: 100%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
        width: 100%;
      }

  h3 {
    margin: 0;
    font-family: 'League Spartan' sans-serif;
    justify-self: flex-start;
    font-size: 3.5vw;
    font-weight: bold;
    color: #323237;

    
  @media (max-width: 600px) {
        font-size: 2em;
      }
  }

  ul {
    color: #323237;
    max-width: 60%;
    text-align: left;
    font-size: 2vw;
    font-weight: 700;
    margin-left: 2em;
    padding: 0;

    @media (max-width: 600px) {
        max-width: 80%;
        font-size: 1em;
        margin-left: 0;
      }

    li {
      margin: 10px;
      padding: 0;
      margin-left: 1em;
    }
   }
  }
}

.container_texto2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
        margin-top: 20px;
      }

  h3 {
    margin: 0;
    text-align: center;
    font-family: 'Roboto condensed', sans-serif;
    justify-self: center;
    font-size: 2.5vw;
    font-weight: bold;
    color: #323237;

    @media (max-width: 600px) {
        font-size: 2em;
      }
    }

  span {
    color: #323237;
    font-family: 'League Spartan' sans-serif;
    text-align: center;
    font-size: 1.6vw;
    font-weight: 700;
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    @media (max-width: 600px) {
        font-size: 0.8em;
      }
    }
  }
}

.container_verify {
  background: linear-gradient(90deg, rgb(93, 224, 230) 0%, rgb(0, 74, 173) 100%);

  .container_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 600px) {
        width: 100%;
        font-size: 1em;
      }

    h3 {
      text-align: center;
      margin: 3rem 0 0 0;
      font-family: 'League Spartan';
      font-size: 3em;
      font-weight: bold;
      color: #323237;

      @media (max-width: 600px) {
        margin: 1rem 0 0 0;
        font-size: 2em;
      }
    }
  }

  .container_middle {
    margin: 3rem 0 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  
    @media (max-width: 600px) {
        width: 100%;
        flex-direction: column;
        padding: 0;

      }

    .div_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 600px) {
        width: 100%;
      }

      p {
        color: #323237;
        width: 60%;
        font-family: 'Arial Nova Cond', sans-serif;
        font-size: 2.5vw;
        font-weight: bold;
        padding: 0;
        margin-bottom: 1em;

        @media (max-width: 600px) {
        width: 80%;
        font-size: 1em;
        }

        span {
          color: red;
        }
      }

      input {
        border: 3px solid rgb(255, 139, 55); 
        width: 50%;
        text-align: center;
        font-size: 1.5vw;
        height: 40px;
        border-radius: 10px;
        margin-bottom: 1em;
        outline: none;
        
        @media (max-width: 600px) {
         font-size:  0.7em;
         margin-bottom: 2em;
          }
        }

      .error_message {
        color: #080707;
        font-weight: 800;
        font-size: 18px;
        font-family: 'League Spartan';
        margin-bottom: 15px;
      }

      .btn_consultar {
        color: white;
        background: rgb(255, 139, 55);
        padding: 15px;
        font-size: 16px;
        border: none;
        border-radius: 15px;
        margin-bottom: 15px;
        box-shadow: 0px 0px 2px 2px rgba(45, 45, 45, 0.3);

          @media (max-width: 600px) {
            padding: 10px;
            font-size: 12px;
          }
        }
      }
    }

    .div_img {
      max-width: 45%;
      @media (max-width: 600px) {
        max-width: 90%;
      }

      img {
        width: 100%;
        height: 100%;

        @media (max-width: 600px) {
        border-radius: 10px;
        box-shadow: 0px 0px 2px 2px rgba(255, 255, 255);
      }
      }
    }
    
    .container_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #323237;
      font-family: 'League Spartan';
      font-size: 2em;
      font-weight: 900;
      padding-bottom: 2em;

      span {
        @media (max-width: 600px) {
        text-align: center;
        align-self: center;
        justify-self: center;
        width: 80%;
        font-size: 1.3rem;
      }
      }
    
    }
  }

  .Faqs {
    display: flex;
    justify-content: space-between;
    align-items: center;
        
    @media (max-width: 600px) {
        width: 100%;
        flex-direction: column;
      }


    .container_img {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
              
      @media (max-width: 600px) {
          width: 100%;
          margin-bottom: 1em;
        }
      
      img {
        width: 100%;

        @media (max-width: 850px) {
          width: 70%;
          align-self: center;
        }
      }
    }
  }

  .container_gavetas {
      display: flex;
      flex-direction: column;
      background: #7ED957;
      width: 50%;
      height: 100%;
      padding: 30px 10px 30px 10px;
      border-radius: 20px;
      margin: 20px 0 20px 0;
      justify-content: center;
                
      @media (max-width: 600px) {
          width: 80%;
          margin: 0 0 10px 0;
        }

      .gaveta {
        border: 1px solid #ffffff;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        height: 30px;
        padding: 10px;
        border-radius: 10px;
        background: #ffffff;
        margin: 10px;
        cursor: pointer;
        transition: height 0.5s;
        overflow: hidden;

        h3 {
          font-family: 'Roboto Condensed', sans-serif;
          font-weight: 900;
          font-size: 1em;
        }
    }

    .gaveta.aberto {
        background-color: #ffffff; /* Cor de fundo quando aberto */
        height: 200px; /* Altura expandida da gaveta quando aberta */
    }

    .conteudo {
        padding: 10px;
        margin: 5px;
        display: none;
    }

    .conteudo.aberto {
        display: flex;
        flex-wrap: wrap;
        height: 150px;
        background-color: #f9f9f9; /* Cor de fundo quando aberto */
        overflow-x: auto;

          p {
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 1em;
            font-weight: 400;
            text-align: justify;
          }
      }
    }
  
  .footer {
      background: rgb(218, 218, 225);
      display: flex;
      max-height: 200px;
      justify-content: space-between;

      @media (max-width: 850px) {
        max-height: 300px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .contato {
        margin: 1rem;
        display: flex;
        align-items: center;
        max-width: 30%;
  
        .email {
          margin: 1rem;
          background: #000000; 
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 25%;
          min-width: 20%;    

          @media (max-width: 850px) {
            max-width: 50%;
            min-width: 30%;    
          }
            img {
              width: 100%;
            }
          }
         
        .email_texto {
          display: flex;
          flex-direction: column;
          justify-content: center;
          
            h3 {
            font-size: 1.3em;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 700;
            margin: 0;
          }

          p {
            margin-top: 1px;
            font-size: 1em;
            font-family: 'Roboto Condensed', sans-serif;
            color: rgb(109, 143, 171);
            font-weight: 700;
          }
        }
      }

      .redes {
        margin: 1rem;
        align-self: flex-end;
        display: flex;
        min-width: 30%;
        max-width: 20%;
        height: 20%;
        min-height: 50%;
        align-items: center;
        justify-content: center;

        @media (max-width: 850px) {
        margin-top: 30px;
        margin-bottom: 30px;
        min-width: 70%;
        max-width: 70%;
        align-self: flex-start;
        justify-content: flex-start;
        }

        @media (max-width: 400px) {
        min-width: 100%;
        max-width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        }

        .face {
        margin-right: 1rem;    
        background: #000000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        width: 10%;
          img {
            width: 100%;
          }
      }

      .twitter {
        margin-right: 1rem;    
        background: #000000;
        border-radius: 50%;
        display: flex;
        width: 10%;
        align-items: center;
        justify-content: center;        
          img {
            width: 100%;
          }
        }

      .insta {
        margin-right: 1rem;    
        background: #000000;
        display: flex;
        border-radius: 10px;
        width: 10%;
         img {
            width: 100%;
          }
        }

      .in {
        margin-left: 1rem;
        margin-right: 1rem;    
        background: #000000;
        display: flex;
        border-radius: 10px;
        width: 10%;
          img {
            width: 100%;
          }
        }
      }

      .infos {
        margin: 1rem;
        padding: 1rem;    
        h3 {
          font-size: 1.2em;
          font-family: 'Roboto Condensed', sans-serif;
          font-weight: 700;
          margin: 0;
        }

        p {
          margin: 0;
          font-size: 1em;
          font-family: 'Roboto Condensed', sans-serif;
          font-weight: 700;
        }
      }   
    }
`;
