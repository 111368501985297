import React from "react";

const EditarImagem = ({ img, handleImageUpload, setImagem, imagemInicial, carteiraId, save, isRecepcionada }) => {
  return (
    <>
      <input
        className="inputEditarImagem"
        type="file"
        name="imagem"
        onChange={(e) => {
          const file = e.target.files[0];
          setImagem(carteiraId, file); // Atualiza o estado local
          handleImageUpload(carteiraId, file); // Faz o upload da imagem imediatamente
        }}
        disabled={!isRecepcionada && save !== carteiraId}
        />
      {img ? (
        <img
          className="editarImagem"
          src={URL.createObjectURL(img)}
          alt="imagem"
        />
      ) : (
        <img className="editarImagem" src={imagemInicial} alt="imagem" />
      )}
    </>
  );
};

export default EditarImagem;