import styled from "styled-components";
import {Add} from "@styled-icons/fluentui-system-filled/Add"

export const ButtonCriar = styled(Add)`
width: 35px;
height: 35px;
padding: 15px 25px 15px 25px;
border-radius: 50px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

`;
export const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;

  .container_filtro {
    display: flex;
    align-items: center;

    .form_dados {
        margin:10px;

        input {
            width: 150px;
            padding: 20px;
            border-radius: 15px;
            border: none;
            font-size: 1.5em;
            font-family: "League Gothic", sans-serif;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
        }

        input::placeholder {
            text-align: center;
        }
    }
  }
`;
