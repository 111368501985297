import React, { useState, useEffect, useCallback, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { Container, ButtonCriar } from "../styled/FiltroMobile";
import { requestData } from "../services/requests";
import { cpfMask } from "../services/funcoesAuxiliares";
import Modal from "./modal";

const FiltrarDadosMobile = ({ setError, setFiltro, currentStep, setCurrentStep }) => {
  const hoje = moment().format().split("T")[0];
  const mesPassado = moment().subtract(1, "month").format();
  const MesAnterior = mesPassado.split("T")[0];

  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = jwtDecode(user);
  const codUsoRef = useRef(null);
  const nomeAlunoRef = useRef(null);
  const [carteiras, setCarteiras] = useState([]);
  const [codigoUso, setCodigoUso] = useState("");
  const [cpf, setCpf] = useState("");
  const [modalVisivel, setModalVisivel] = useState(false);
  const [timer, setTimer] = useState(null);

  const filtrar = async (e) => {
    e.preventDefault();
    const value =  e.target.value;
    const formattedCpf = cpfMask(value); // Formata o CPF diretamente
    setCpf(formattedCpf);
  
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      try {

        if (!formattedCpf.trim()) {
          // Se o CPF estiver vazio, limpa o filtro e retorna
          limparFiltro(e)
          return;
        }

        const filtroGestores = await requestData(
          `/carteiras/filtro?codigoUso=${codigoUso}&cpf=${formattedCpf}&gestor=${[data.id]}&selectedStatus=${[]}`
        );
        setFiltro(filtroGestores);
        setError(false);
      } catch (error) {
        setError(true);
      }
    }, 2000);
    // Salva o timer para que ele possa ser limpo na próxima alteração
    setTimer(newTimer);   
  };

  const limparFiltro = async (e) => {
    e.preventDefault();
    setCodigoUso("");
    setFiltro([]);
    setCpf("");
    setError(false);
  };

  return (
    <Container>
      <div className="container_filtro">
        <form className="form_dados">
          <input
            type="tel"
            id="cpf"
            value={cpf}
            onChange={filtrar}
            placeholder="CPF / CODIGO DE USO"
          />
        </form>
        <>
          <ButtonCriar className="btn_criar" onClick={() => setModalVisivel(true)} />
          {modalVisivel && (
            <Modal
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            modalVisivel={modalVisivel}
            setModalVisivel={setModalVisivel}
            />
          )}
        </>
      </div>
    </Container>
  );
};

export default FiltrarDadosMobile;
