import React, { useState, useEffect } from "react";
import {
  DivCarteira,
} from "../styled/CriarCarteiraMobile";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { cpfMask } from "../services/funcoesAuxiliares";
import InputMask from "react-input-mask";
const { criarCarteiras, uploadImage, apiUrl } = require("../services/requests");

const CriarCarteiraMobile = ({
  setModalVisivel,
  currentStep,
  setCurrentStep,
}) => {
  const date = new Date();
  const newDate = moment(date).locale("pt-br").format("YYYY-MM-DD HH:mm:ss");
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  const user = JSON.parse(localStorage.getItem("user"));
  const [capture, setOnCapture] = useState("");

  const {
    data: { id },
  } = jwtDecode(user);

  const [nome, setNome] = useState("");
  const [instituicaoEnsino, setInstituicaoEnsino] = useState("");
  const [curso, setCurso] = useState("");
  const [imagemInicial] = useState(`${apiUrl}/images/0.png`);
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [dataEmissao] = useState(newDate);
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [codigoUso, setCodigoUso] = useState("");

  useEffect(() => {
    const AnoMes = moment().format("YYMM");
    let random2 = "";
    let d = 0;
    while (d < 3) {
      random2 += Math.floor(Math.random() * 10);
      d++;
    }
    let random1 = "";
    let c = 0;
    while (c < 3) {
      random1 += Math.floor(Math.random() * 10);
      c++;
    }

    const newCodigoUso = `${AnoMes}-${random1}.${random2}`;
    setCodigoUso(newCodigoUso);
  }, []);

  const criarCarteira = async (event) => {
    event.preventDefault();

    if (isButtonDisabled || isSubmitting) return;
    setIsSubmitting(true);
    try {
      const endpoint = "/carteiras";
      const upload = "/upload-image";

      if (capture !== "") {
        const formData = new FormData();
        formData.append("image", capture);

        await uploadImage(upload, formData);
      }

      const response = await criarCarteiras(endpoint, {
        foto: capture === "" ? imagemInicial : `${apiUrl}/images/${capture.name}`,
        codigoUso,
        nome,
        instituicaoEnsino,
        curso,
        cpf,
        rg,
        dataNascimento,
        dataValidade,
        dataEmissao,
        user_id: id,
        email,
        telefone,
        observacao: "",
      });
      handleClick(false);
      setError(false);
      alert("Carteira Criada Com Sucesso!");
      setNome("");
      setInstituicaoEnsino("");
      setCurso("");
      setCpf("");
      setRg("");
      setDataNascimento("");
      setEmail("");
      setTelefone("");
      return response;
    } catch (error) {
      if (error.request.status === 409) {
        setError(true);
      }
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const formData = [nome, instituicaoEnsino, curso, cpf, rg, dataNascimento];

    const isValidFormData = formData.every((value) => value !== "");
    setIsButtonDisabled(!isValidFormData);
  }, [nome, instituicaoEnsino, curso, cpf, rg, dataNascimento]);

  const handleClick = (value) => {
    setModalVisivel(value);
    window.location.reload();
  };
  console.log(capture)
  return (
    <DivCarteira>
      {currentStep === 1 && (
        <div className="container_dados">
          <input
            value={nome}
            id="nome"
            type="text"
            placeholder="NOME"
            onChange={(e) => setNome(e.target.value.toUpperCase())}
          />
          <input
            id="InstEnsino"
            placeholder="INSTITUIÇÃO DE ENSINO"
            value={instituicaoEnsino}
            type="text"
            onChange={(e) => setInstituicaoEnsino(e.target.value.toUpperCase())}
          />
          <input
            id="Curso"
            placeholder="CURSO"
            value={curso}
            type="text"
            onChange={(e) => setCurso(e.target.value.toUpperCase())}
          />
          <input
            id="cpf"
            placeholder="CPF"
            value={cpf}
            type="text"
            onChange={(e) => setCpf(cpfMask(e.target.value))}
          />
          <input
            id="RG"
            placeholder="RG"
            type="text"
            value={rg}
            onChange={(e) => setRg(e.target.value)}
          />
          <InputMask
            mask="99/99/9999"
            id="DataNascimento"
            placeholder="DATA DE NASCIMENTO"
            type="text"
            value={dataNascimento}
            onChange={(e) => setDataNascimento(e.target.value)}
          />
          <InputMask
            mask="(99) 9 9999-9999"
            id="telefone"
            placeholder="TELEFONE"
            type="tel"
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
          />
          <div className="buttons">
            {error ? <p>{"CPF existente"}</p> : null}
            <button
              type="submit"
              className="confirmar"
              disabled={isButtonDisabled || isSubmitting}
              onClick={criarCarteira}
            >
              CRIAR
            </button>
            <button className="fechar" onClick={() => handleClick(false)}>
              FECHAR
            </button>
          </div>
        </div>
      )}
    </DivCarteira>
  );
};

export default CriarCarteiraMobile;
