import React, { useState, useMemo, useEffect } from "react";
import moment from "moment-timezone";
import { Page } from "../styled/Pagination";
import copy from "copy-to-clipboard";
import EditarImagem from "./EditarImagem";
import { DivCentral, Up, Down } from "../styled/NovoPainel";
import api, { uploadImage, requestData, apiUrl } from "../services/requests";
import { QRCodeCanvas } from "qrcode.react";
import CriarCarteira from "./criarCarteira";

const { criarCarteiras } = require("../services/requests");

const Painel = ({ merged, filtros, isAdmin, isSingleColumn, getCarteiras}) => {
  const date = new Date();
  const url = `https://www.uniscestudantil.org.br/carteiras/`;
  const newDate = moment(date).locale("pt-br").format("YYYY-MM-DD HH:mm:ss");
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  let PageSize = 10;
  const [page, setCurrentPage] = useState(1);
  const [openAcordeon, setOpenAcordeon] = useState(null);
  const [carteiras, setCarteiras] = useState([]);
  const [save, setSave] = useState(false);
  const [imagens, setImagens] = useState("");
  const [gestores, setGestores] = useState([]);

  const setImagem = (id, file) => {
    setImagens((prevState) => ({
      ...prevState,
      [id]: file, 
    }));
  };

  const getGestores = async () => {
    const endpoint = "/user";
    const gestores = await requestData(endpoint);
    setGestores(gestores);
  };

  const newArrayGestores = gestores.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  );

  const toggleSave = (id) => {
    setSave((prevId) => (prevId === id ? null : id));
  };

  const toggleListVisibility = (id) => {
    setOpenAcordeon((prevId) => (prevId === id ? null : id));
  };

  const handleImageUpload = async (carteiraId, file) => {
    try {
      if (file) {
        const upload = "/upload-image";
        const formData = new FormData();
        formData.append("image", file);
  
        await uploadImage(upload, formData); // Faz o upload da imagem
  
        // Atualiza a carteira no banco com a nova URL da foto
        await api.patch(`/carteiras/editar/${carteiraId}`, {
          foto: `${apiUrl}/images/${file.name}`,
        });
  
        alert("Imagem atualizada com sucesso!");
        getCarteiras(); // Atualiza as carteiras na interface
      }
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      alert("Erro ao atualizar a imagem.");
    }
  };


  const handleInputChange = async (e, id) => {
    const { name, value } = e.target;

    if(name === 'status') {
      await api.patch(`/carteiras/editar/status/${id}`, {
        status: value,
      });
    }

    setCarteiras((prevCarteiras) =>
      prevCarteiras.map((carteira) =>
        carteira.id === id ? { ...carteira, [name]: value } : carteira
      )
    );
  };  

  const handleSave = async (e, id) => {
    const carteira = carteiras.find((carteira) => carteira.id === id);
    try {
      if (imagens[id] !== undefined) {
        const upload = "/upload-image";
        const formData = new FormData();
        formData.append("image", imagens[id]);
        await uploadImage(upload, formData);
      }

      await api.patch(`/carteiras/editar/${id}`, {
        ...carteira,
        nome: carteira.name,
        foto:
          imagens[id] === undefined
            ? carteira.foto
            : `${apiUrl}/images/${imagens[id].name}`,
      });
      await api.patch(`/carteiras/editar/status/${id}`, {
        status: carteira.status,
      });
      getCarteiras()
      alert("Salvo com sucesso!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = async (carteira) => {
    try {
      const endpoint = "/carteiras/renovacao";
      await criarCarteiras(endpoint, {
        foto: carteira.foto,
        codigoUso: carteira.codigoUso,
        nome: carteira.name,
        instituicaoEnsino: carteira.instituicaoEnsino,
        curso: carteira.curso,
        cpf: carteira.cpf,
        rg: carteira.rg,
        dataNascimento: carteira.dataNascimento,
        dataValidade,
        dataEmissao: newDate,
        user_id: carteira.user_id,
        email: carteira.email,
        telefone: carteira.telefone,
      });
      window.location.reload();
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  };

  function periodoDeRenovacao(carteira) {
    const dataHoje = newDate.split(" ")[0];
    const anoDataValidade = carteira.dataValidade.split("-")[0];
    const periodoRenovacao = new Date(anoDataValidade, 0, 1)
      .toISOString()
      .split("T")[0];
    const validadeCarteira = carteira.dataValidade.split("T")[0];
    return dataHoje >= periodoRenovacao && dataHoje <= validadeCarteira;
  }

  const carteirasOrdenadas =
    filtros && filtros.length > 0
      ? filtros.sort((a, b) =>
          a.dataEmissao > b.dataEmissao
            ? -1
            : a.dataEmissao < b.dataEmissao
            ? 1
            : 0
        )
      : merged.sort((a, b) =>
          a.dataEmissao > b.dataEmissao
            ? -1
            : a.dataEmissao < b.dataEmissao
            ? 1
            : 0
        );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return carteirasOrdenadas.slice(firstPageIndex, lastPageIndex);
  }, [page, PageSize, carteirasOrdenadas]);

  useEffect(() => {
    setCarteiras(currentTableData);
    getGestores();
  }, [currentTableData]);

  const copyToClipboard = (text) => {
    const url = `${window.location.origin}/carteiras/${text}`;
    copy(url);
  };

  const copyDados = (carteira) => {
    const texto = `${carteira.name}\n${carteira.instituicaoEnsino}\n${carteira.curso}\nCPF: ${carteira.cpf}\nRG: ${carteira.rg}\nData Nasc: ${carteira.dataNascimento}`;
    copy(texto);
  };

  return (
    <>
      <DivCentral>
        <div className={`container ${isSingleColumn ? "single-column" : ""}`}>
          {page === 1 && filtros.length === 0 && <CriarCarteira getCarteiras={getCarteiras}/>}
          {carteiras.map((carteira) => (
            <div
              className={`div_carteiras ${
                openAcordeon === carteira.id ? "open" : "closed"
              }`}
              key={carteira.id}
            >
              <div className="separar_divs">
                <div
                  className="header_carteiras"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="div_foto">
                    <EditarImagem
                      img={imagens[carteira.id]}
                      handleImageUpload={handleImageUpload}
                      setImagem={setImagem}
                      imagemInicial={carteira.foto}
                      carteiraId={carteira.id}
                      save={save}
                      isRecepcionada={carteira.status === "Recepcionada"}
                    />
                  </div>
                  <div className="div_dados">
                    <input
                      className="dados_input"
                      placeholder="NOME"
                      name="name"
                      value={carteira.name}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                      aria-label="Nome completo"
                      disabled={save === carteira.id ? false : true}
                    />
                    <input
                      className="dados_input"
                      placeholder="INSTIUIÇÃO DE ENSINO"
                      name="instituicaoEnsino"
                      value={carteira.instituicaoEnsino}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                      disabled={save === carteira.id ? false : true}
                    />
                    <input
                      className="dados_input"
                      placeholder="CURSO"
                      name="curso"
                      value={carteira.curso}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                      disabled={save === carteira.id ? false : true}
                    />
                    <div className="linha">
                      <label className="dados_label">
                        <span>CPF:</span>
                        <input
                          className="input_label"
                          name="cpf"
                          value={carteira.cpf}
                          onChange={(e) => handleInputChange(e, carteira.id)}
                          disabled={save === carteira.id ? false : true}
                        />
                      </label>
                    </div>
                    <div className="linha">
                      <label className="dados_label">
                        <span>RG:</span>
                        <input
                          className="input_label"
                          placeholder=""
                          name="rg"
                          value={carteira.rg}
                          onChange={(e) => handleInputChange(e, carteira.id)}
                          disabled={save === carteira.id ? false : true}
                        />
                      </label>
                      <label className="dados_label">
                        <span>DN:</span>
                        <input
                          className="input_label"
                          placeholder=""
                          name="dataNascimento"
                          value={carteira.dataNascimento}
                          onChange={(e) => handleInputChange(e, carteira.id)}
                          disabled={save === carteira.id ? false : true}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="status"> 
                  <h4>Status:</h4>
                    <select
                      id="status_carteira"
                      name="status"
                      value={carteira.status}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                      selected={carteira.status}
                      style={{
                        color:
                          carteira.status === "Recepcionada"
                            ? "black"
                            : carteira.status === "Validada"
                            ? "#4CC800"
                            : carteira.status === "Cancelada"
                            ? "red"
                            : "black",
                      }}
                    >
                      <option style={{ color: "black" }} value="Recepcionada">
                        Recepcionada
                      </option>
                      <option style={{ color: "#4CC800" }} value="Validada">
                        Validada
                      </option>
                      <option disabled value="Renovação">
                        Renovação
                      </option>
                      <option disabled value="Expirada">
                        Expirada
                      </option>
                      <option style={{ color: "red" }} value="Cancelada">
                        Cancelada
                      </option>
                    </select>
                  </div>
                  <div className="buttons">
                    <div className="infos">
                      <QRCodeCanvas
                        className="qrCode"
                        value={url + carteira.id}
                        size={100}
                        marginSize={1}
                      />
                      <span className="codigoUso"> {carteira.codigoUso}</span>
                      {periodoDeRenovacao(carteira) === true ||
                      carteira.status === "Expirada" ? (
                        <button
                          className="btn_renovacao"
                          onClick={() => handleClick(carteira)}
                        >
                          RENOVAÇÃO{" "}
                        </button>
                      ) : (
                        <button
                          className="btn_copiar"
                          onClick={() => copyDados(carteira)}
                        >
                          {" "}
                          DADOS{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="btn_toggle"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleListVisibility(carteira.id);
                  }}
                >
                  {openAcordeon === carteira.id ? <Up /> : <Down />}
                </button>
              </div>
              {openAcordeon === carteira.id && (
                <div className="acordeon">
                  <div className="acordeon_inputs">
                    <textarea
                      className="observacoes"
                      name="observacao"
                      value={carteira.observacao}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                    />
                    <input
                      className="telefone"
                      name="telefone"
                      placeholder="TELEFONE"
                      value={carteira.telefone}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                    />
                    <input
                      className="email"
                      name="email"
                      placeholder="EMAIL"
                      value={carteira.email}
                      onChange={(e) => handleInputChange(e, carteira.id)}
                    />
                  </div>
                  <div className="acordeon_infos">
                    <div className="acordeon_dados_emissao">
                      <h4>Data Emissao</h4>
                      <span>
                        {moment(carteira.dataEmissao)
                          .utcOffset("-0000")
                          .format("HH:mm:ss DD/MM/YYYY")}
                      </span>
                    </div>
                    <div className="acordeon_dados_validade">
                      <h4>Data Validade</h4>
                      <span>
                        {" "}
                        {moment(carteira.dataValidade)
                          .locale("pt-br")
                          .format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="status">
                   
                    {isAdmin && (
                      <>
                        <h4>Gestores:</h4>
                        <select
                          id="carteira_gestor"
                          name="user_id"
                          value={carteira.user_id}
                          onChange={(e) => handleInputChange(e, carteira.id)}
                          disabled={save === carteira.id ? false : true}
                        >
                          {newArrayGestores.map((gestor) => (
                            <option key={gestor.id} value={gestor.id}>
                              {" "}
                              {gestor.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>
                  <div className="acordeon_buttons">
                    <button
                      className="copiar_url"
                      onClick={() => copyToClipboard(carteira.id)}
                    >
                      URL
                    </button>
                    <button
                      className="editar"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSave(carteira.id);
                      }}
                    >
                      {save === carteira.id ? (
                        <button
                          className="salvar"
                          onClick={(e) => {
                            handleSave(e, carteira.id);
                          }}
                        >
                          {" "}
                          SALVAR{" "}
                        </button>
                      ) : (
                        "EDITAR"
                      )}
                    </button>
                    <a
                      className="visualizar"
                      href={`/carteiras/${carteira.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      VISUALIZAR
                    </a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </DivCentral>
      <div className="Resultados">
        {" "}
        {filtros.length || merged.length} Resultados encontrados{" "}
      </div>
      <Page
        className="pagination-bar"
        currentPage={page}
        totalCount={filtros.length || merged.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default Painel;
