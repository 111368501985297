import styled from "styled-components";
import {ArrowForward} from "@styled-icons/material-outlined/ArrowForward"
import {ArrowBack} from "@styled-icons/material-outlined/ArrowBack"

export const ArrowBackButton = styled(ArrowBack)`
width: 40px;
height: 40px;
border: none; 
color: white;
background-color: transparent;
`;

export const ArrowForwardButton = styled(ArrowForward)`
width: 40px;
height: 40px;
border: none;
color: white;
background-color: transparent;

`;

export const DivCarteira = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background-color: #3b71da;
  z-index: 0;

  .container_dados {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 0;

    input {
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      font-weight: 700;

      @media screen and (max-height: 700px) {
        margin: 5px;
        padding: 10px;
      }
    }
  }

  .buttons {
    height: 100px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .confirmar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-size: 20px;
      background-color: #3e4095;
      color: white;
      font-family: "League Gothic", sans-serif;
      border: none;
      text-align: center;
      box-sizing: border-box;
      width: 100px;
    }

    .fechar {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-size: 20px;
      background-color: #65a140;
      color: white;
      font-family: "League Gothic", sans-serif;
      border: none;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      margin-bottom: 5px;
      width: 100px;
    }
  }

  .confirmation-step {
    position: relative;
    z-index: 999;
    height: 100vh;
    width: 100%;
    margin: 20px;
    background-color: black;
    background-size: cover;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin: 0; 
    padding: 0; 

    @media screen and (max-height: 700px) {
      height: calc(100vh - env(safe-area-inset-bottom));
      top: 50px;
    }

    .confirmation {
      width: 100%;
      height: calc(100vh - env(safe-area-inset-bottom));

      img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      .buttons {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 5px;
        bottom: 150px;

        @media screen and (max-height: 700px) {
          bottom: 100px;
        }


        .forward {
          border: none;
          background-color: transparent;

        }

        .back {
          border: none;
          background-color: transparent;

        }
      }
    }
  }
`;
