import axios from 'axios';

export const apiUrl = "https://api.uniscestudantil.org.br";
//export const apiUrl = "http://localhost:3001";

const api = axios.create({
  baseURL: apiUrl,
});


export const setToken = (token) => {
  api.defaults.headers.common.Authorization = token;
};

export const requestData = async (endpoint) => {
  const { data } = await api.get(endpoint);
  return data;
};

export const requestLogin = async (endpoint, body) => {
  const { data } = await api.post(endpoint, body);
  return data;
};

export const uploadImage = async (endpoint, body) => {
  const { data } = await api.post(endpoint, body);
  return data;
};

export const criarCarteiras = async (endpoint, body) => {
  const { data } = await api.post(endpoint, body);
  return data;
};

export const requestWithToken = async (endpoint, token) => {
  const { data } = await api.get(endpoint, {
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export default api;
