import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Painel from "./painel";
import moment from "moment-timezone";
import { requestData } from "../services/requests";
import { jwtDecode } from "jwt-decode";
import { funcaoOrganizar } from "../services/funcoesAuxiliares";
import PainelMobile from "./painelMobile";

const Carteiras = ({ error, filtro, isSingleColumn }) => {
  const [carteirasBd, setCarteiras] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    data: { role, id },
  } = jwtDecode(user);
  const [bloqueio, setBloqueio] = useState(null);
  const isAdmin = role === "administrador";
  const merged = funcaoOrganizar(carteirasBd);
  const largura =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const navigate = useNavigate();

  const hoje = moment().format().split("T")[0];
  const mesPassado = moment().subtract(1, "month").format();
  const MesAnterior = mesPassado.split("T")[0];

  const getCarteiras = useCallback(async () => {
    const carteirasGestor = await requestData(
      `/carteiras/?id=${id}&dataInicial=${MesAnterior}&dataFinal=${hoje}`
    );
    setCarteiras(carteirasGestor);
  }, [id, hoje, MesAnterior]);

  const getUser = useCallback(async () => {
    const user = await requestData(`/user/${id}`);
    setBloqueio(user.bloqueio);
  }, [id]);

  useEffect(() => {
    getCarteiras();
    getUser();
    if (bloqueio === true) {
      localStorage.removeItem("user");
      alert("BLOQUEADO!!!");
      return navigate("/login");
    }
  }, [getCarteiras, navigate, bloqueio, getUser]);

  if (error) {
    return <p> Informação Não Encontrada! </p>;
  } else {
    const filtros = funcaoOrganizar(filtro);

    if (largura >= 1000) {
      return <Painel isSingleColumn={isSingleColumn} merged={merged} filtros={filtros} isAdmin={isAdmin} getCarteiras={getCarteiras}/>
    } else {
      return (
        <PainelMobile merged={merged} filtros={filtros} isAdmin={isAdmin} getCarteiras={getCarteiras}/>
      );
    }
  }
};

export default Carteiras;
