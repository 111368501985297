import React, { useState, useMemo, useEffect } from "react";
import moment from "moment-timezone";
import { Page } from "../styled/Pagination";
import copy from "copy-to-clipboard";
import { DivCentral } from "../styled/painelMobile";
import api, { uploadImage, requestData, apiUrl } from "../services/requests";
import CriarCarteira from "./criarCarteira";
import EditarImagem from "./EditarImagem";

const { criarCarteiras } = require("../services/requests");

const PainelMobile = ({ merged, filtros, isAdmin }) => {
  const date = new Date();
  const url = `https://www.uniscestudantil.org.br/carteiras/`;
  const newDate = moment(date).locale("pt-br").format("YYYY-MM-DD HH:mm:ss");
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  let PageSize = 10;
  const [page, setCurrentPage] = useState(1);
  const [openAcordeon, setOpenAcordeon] = useState(null);
  const [carteiras, setCarteiras] = useState([]);
  const [save, setSave] = useState(false);
  const [imagens, setImagens] = useState("");
  const [gestores, setGestores] = useState([]);

  const setImagem = (id, file) => {
    setImagens((prevState) => ({
      ...prevState,
      [id]: file,
    }));
  };

  const handleImageUpload = async (carteiraId, file) => {
    try {
      if (file) {
        const upload = "/upload-image";
        const formData = new FormData();
        formData.append("image", file);

        await uploadImage(upload, formData); // Faz o upload da imagem

        // Atualiza a carteira no banco com a nova URL da foto
        await api.patch(`/carteiras/editar/${carteiraId}`, {
          foto: `${apiUrl}/images/${file.name}`,
        });

        alert("Imagem atualizada com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      alert("Erro ao atualizar a imagem.");
    }
  };

  const getGestores = async () => {
    const endpoint = "/user";
    const gestores = await requestData(endpoint);
    setGestores(gestores);
  };

  const handleInputChange = async (e, id) => {
    const { name, value } = e.target;

    if (name === "status") {
      await api.patch(`/carteiras/editar/status/${id}`, {
        status: value,
      });
    }

    setCarteiras((prevCarteiras) =>
      prevCarteiras.map((carteira) =>
        carteira.id === id ? { ...carteira, [name]: value } : carteira
      )
    );
  };

  function periodoDeRenovacao(carteira) {
    const dataHoje = newDate.split(" ")[0];
    const anoDataValidade = carteira.dataValidade.split("-")[0];
    const periodoRenovacao = new Date(anoDataValidade, 0, 1)
      .toISOString()
      .split("T")[0];
    const validadeCarteira = carteira.dataValidade.split("T")[0];
    return dataHoje >= periodoRenovacao && dataHoje <= validadeCarteira;
  }

  const handleClick = async (carteira) => {
    try {
      const endpoint = "/carteiras/renovacao";
      await criarCarteiras(endpoint, {
        foto: carteira.foto,
        codigoUso: carteira.codigoUso,
        nome: carteira.name,
        instituicaoEnsino: carteira.instituicaoEnsino,
        curso: carteira.curso,
        cpf: carteira.cpf,
        rg: carteira.rg,
        dataNascimento: carteira.dataNascimento,
        dataValidade,
        dataEmissao: newDate,
        user_id: carteira.user_id,
        email: carteira.email,
        telefone: carteira.telefone,
      });
      window.location.reload();
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  };

  const carteirasOrdenadas =
    filtros && filtros.length > 0
      ? filtros.sort((a, b) =>
          a.dataEmissao > b.dataEmissao
            ? -1
            : a.dataEmissao < b.dataEmissao
            ? 1
            : 0
        )
      : merged.sort((a, b) =>
          a.dataEmissao > b.dataEmissao
            ? -1
            : a.dataEmissao < b.dataEmissao
            ? 1
            : 0
        );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return carteirasOrdenadas.slice(firstPageIndex, lastPageIndex);
  }, [page, PageSize, carteirasOrdenadas]);

  useEffect(() => {
    setCarteiras(currentTableData);
    getGestores();
  }, [currentTableData]);

  const copyToClipboard = (text) => {
    const url = `${window.location.origin}/carteiras/${text}`;
    copy(url);
  };

  return (
    <>
      <DivCentral>
        <div className="container">
          {carteiras.map((carteira) => (
            <div className="div_carteiras" key={carteira.id}>
              <div className="div_foto">
                <div className="carteira_img">
                  <EditarImagem
                    img={imagens[carteira.id]}
                    handleImageUpload={handleImageUpload}
                    setImagem={setImagem}
                    imagemInicial={carteira.foto}
                    carteiraId={carteira.id}
                    save={save}
                    isRecepcionada={carteira.status === "Recepcionada"}
                  />
                </div>
                <select
                  id="status_carteira"
                  name="status"
                  value={carteira.status}
                  onChange={(e) => handleInputChange(e, carteira.id)}
                  selected={carteira.status}
                  readOnly
                  style={{
                    color:
                      carteira.status === "Recepcionada"
                        ? "black"
                        : carteira.status === "Validada"
                        ? "#4CC800"
                        : carteira.status === "Cancelada"
                        ? "red"
                        : "black",
                  }}
                >
                  <option style={{ color: "black" }} value="Recepcionada">
                    Recepcionada
                  </option>
                  <option style={{ color: "#4CC800" }} value="Validada">
                    Validada
                  </option>
                  <option disabled value="Renovação">
                    Renovação
                  </option>
                  <option style={{ color: "red" }} disabled value="Expirada">
                    Expirada
                  </option>
                  <option style={{ color: "red" }} value="Cancelada">
                    Cancelada
                  </option>
                </select>
              </div>
              <div className="header_carteiras">
                <div className="div_dados">
                  <input
                    className="dados_input"
                    placeholder="NOME"
                    name="name"
                    value={carteira.name}
                    aria-label="Nome completo"
                    readOnly
                  />
                  <input
                    className="dados_input"
                    placeholder="INSTIUIÇÃO DE ENSINO"
                    name="instituicaoEnsino"
                    value={carteira.instituicaoEnsino}
                    readOnly
                  />
                  <input
                    className="dados_input"
                    placeholder="CURSO"
                    name="curso"
                    value={carteira.curso}
                    readOnly
                  />
                  <div className="linha">
                    <label className="dados_label">
                      <span>CPF:</span>
                      <input
                        className="input_label"
                        name="cpf"
                        value={carteira.cpf}
                        readOnly
                      />
                    </label>
                  </div>
                  <div className="linha">
                    <label className="dados_label">
                      <span>RG:</span>
                      <input
                        className="input_label"
                        placeholder=""
                        name="rg"
                        value={carteira.rg}
                        readOnly
                      />
                    </label>
                    <label className="dados_label">
                      <span>DN:</span>
                      <input
                        className="input_label"
                        placeholder=""
                        name="dataNascimento"
                        value={carteira.dataNascimento}
                        readOnly
                      />
                    </label>
                  </div>
                </div>
                <div className="buttons">
                  <a
                    className="visualizar"
                    href={`/carteiras/${carteira.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    VISUALIZAR
                  </a>
                  {periodoDeRenovacao(carteira) === true ||
                  carteira.status === "Expirada" ? (
                    <button
                      className="btn_renovacao"
                      onClick={() => handleClick(carteira)}
                    >
                      RENOVAÇÃO{" "}
                    </button>
                  ) : (
                    <button
                      className="copiar_url"
                      onClick={() => copyToClipboard(carteira.id)}
                    >
                      URL
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </DivCentral>
      <div className="Resultados">
        {" "}
        {filtros.length || merged.length} Resultados encontrados{" "}
      </div>
      <Page
        className="pagination-bar"
        currentPage={page}
        totalCount={filtros.length || merged.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default PainelMobile;
