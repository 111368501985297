import styled from "styled-components";
import { ArrowUp } from "@styled-icons/bootstrap/ArrowUp"
import { ArrowDown } from "@styled-icons/bootstrap/ArrowDown"


export const Up = styled(ArrowUp)`
`;

export const Down = styled(ArrowDown)`
`;


export const DivCentral = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  .container.single-column {
    grid-template-columns: 1fr;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1500px) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .acordeon {
        display: flex;
        border: 1px solid red;

        transition: height 0.3s ease;
        justify-content: space-between;

        .acordeon_inputs {
          display: flex;
          flex-direction: column;
          width: 30%;

          .observacoes {
            border: none;
            border-radius: 5px;
          }

          .telefone {
            margin-top: 5px;
            border-radius: 5px;
            border: none;
          }

          .email {
            margin-top: 5px;
            border-radius: 5px;
            border: none;
          }
        }

        .acordeon_infos {
          display: flex;
          flex-direction: column;
          font-weight: bold;

          .acordeon_dados_emissao {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 70%;
            font-size: 0.8em;
            h4 {
              margin: 0 0 4px 0;
            }
          }

          .acordeon_dados_validade {
            margin-top: 4px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 0.8em;

            h4 {
              margin: 0 0 2px 0;
            }
          }
        }

        .status {
          display: flex;
          flex-direction: column;
          margin-left: -30px;
          font-size: 0.8em;
          margin-top: -2px;
          h4 {
            margin: 0;
          }

          #status_carteira {
            border: none;
            border-radius: 5px;
            background-color: white;
            font-weight: 700;
            padding: 5px;
            width: 100px;
          }

          #carteira_gestor {
            border: none;
            border-radius: 5px;
            background-color: white;
            font-weight: 700;
            padding: 5px;
            width: 100px;
          }
        }

        .acordeon_buttons {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100px;

          .copiar_url {
            background-color: #ecae04;
            font-family: "League Gothic", sans-serif;
            font-size: 1.2em;
            border: none;
            color: white;
            width: 80%;
            border-radius: 7px;
            cursor: pointer;
          }

          .editar {
            background-color: #65a140;
            transition: background-color 0.3s;
            font-family: "League Gothic", sans-serif;
            font-size: 1.2em;
            border: none;
            color: white;
            width: 100%;
            border-radius: 7px;

            .salvar {
              background-color: #65a140;
              transition: background-color 0.3s;
              font-family: "League Gothic", sans-serif;
              font-size: 1em;
              border: none;
              color: white;
            }
          }

          .visualizar {
            text-align: center;
            background-color: #3e4095;
            text-decoration: none;
            font-family: "League Gothic", sans-serif;
            font-size: 1.2em;
            border: none;
            color: white;
            width: 100%;
            border-radius: 7px;
          }
        }
      }
    }

    @media screen and (max-width: 1100px) {
      display: grid;
      grid-template-columns: 1fr;
    }

    .div_carteiras.open {
      transition: height 0.3s ease;
      grid-row: span 2;
    }

    .div_carteiras.closed {
      grid-row: auto;
      max-height: 250px;
      transition: height 0.3s ease;
    }

    .div_carteiras {
      display: flex;
      flex-direction: column;
      margin: 10px;
      background-color: #aac6fc;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
      border-radius: 15px;
      transition: height 0.3s ease;

      .separar_divs {
        display: flex;

        .header_carteiras {
          width: 95%;
          border-radius: 15px;
          display: flex;
          margin: 10px 0 10px 10px;
          padding: 5px;
          justify-content: space-between;
          
          .div_foto {
            max-width: 140px;
            max-height: 180px;
            border-radius: 15px;

            .inputEditarImagem {
              z-index: 1;
              position: relative;
              opacity: 0;
              width: 100%;
              height: 150px;
              cursor: pointer;

              &:disabled {
                cursor: not-allowed;
                pointer-events: none;
              }
            }

            .editarImagem {
              position: relative;
              z-index: 0;
              top: -150px;
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }

          .div_dados {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin: 0 10px;

            .dados_input {
              margin: 0 5px 5px 5px;
              padding: 5px;
              border-radius: 5px;
              border: none;
              font-weight: 700;

              &:disabled {
                background-color: white;
                color: black;
              }
            }

            .linha {
              display: flex;
              width: 100%;
              font-size: small;
              font-weight: 700;

              .dados_label {
                margin: 5px;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;

                span {
                  margin-right: 5px;
                }

                .input_label {
                  font-weight: 700;
                  width: 100%;
                  padding: 5px;
                  border: none;
                  border-radius: 5px;

                  &:disabled {
                    background-color: white;
                    color: black;
                  }
                }
              }
            }
          }

          .status {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
          margin-bottom: 25px;

          h4 {
            margin: 2px 0 2px 0;
            font-size: 1em;
          }

          #status_carteira {
            border: none;
            border-radius: 5px;
            background-color: white;
            font-weight: 700;
            padding: 5px;
          }

          }

          .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 20%;

            
            .infos {
              display: flex;
              flex-direction: column;

              .codigoUso {
                font-weight: 700;
                margin: 10px 0 10px 0;
              }
            }
            .btn_copiar {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 7px;
              font-size: 20px;
              background-color: #ec0404;
              color: white;
              font-family: "League Gothic", sans-serif;
              border: none;
              cursor: pointer;
              text-align: center;
              box-sizing: border-box;
              margin-bottom: 5px;
            }

            .btn_renovacao {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 7px;
              font-size: 20px;
              background-color: #ec0404;
              color: white;
              font-family: "League Gothic", sans-serif;
              border: none;
              cursor: pointer;
              text-align: center;
              box-sizing: border-box;
              margin-bottom: 5px;
            }
          }
        }

        .btn_toggle {
          width: 5%;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #0056b3;
          font-family: "League Gothic", sans-serif;
          color: #fff;
          border-radius: 7px;
          font-size: 20px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
      }
      .acordeon {
        display: flex;
        margin: 50px 10px 10px 10px;
        padding: 5px;
        transition: height 0.3s ease;
        justify-content: space-between;

        .acordeon_inputs {
          display: flex;
          flex-direction: column;

          .observacoes {
            border: none;
            border-radius: 5px;
            padding: 25px;
          }

          .telefone {
            margin-top: 5px;
            border-radius: 5px;
            border: none;
            padding: 5px;
          }

          .email {
            margin-top: 5px;
            border-radius: 5px;
            padding: 5px;
            border: none;
          }
        }

        .acordeon_infos {
          display: flex;
          flex-direction: column;
          font-weight: bold;

          .acordeon_dados_emissao {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 70%;

            h4 {
              margin: 0 0 4px 0;
              font-size: 1em;
            }
          }

          .acordeon_dados_validade {
            margin-top: 4px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h4 {
              margin: 0 0 2px 0;
              font-size: 1em;
            }
          }
        }

        .status {
          display: flex;
          flex-direction: column;
          margin-left: -30px;
          h4 {
            margin: 2px 0 2px 0;
            font-size: 1em;
          }

          #status_carteira {
            border: none;
            border-radius: 5px;
            background-color: white;
            font-weight: 700;
            padding: 5px;
          }

          #carteira_gestor {
            border: none;
            border-radius: 5px;
            background-color: white;
            font-weight: 700;
            padding: 5px;
          }
        }

        .acordeon_buttons {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100px;

          .copiar_url {
            background-color: #ecae04;
            font-family: "League Gothic", sans-serif;
            font-size: 1.2em;
            border: none;
            color: white;
            width: 100%;
            border-radius: 7px;
            cursor: pointer;
          }

          .editar {
            background-color: #65a140;
            transition: background-color 0.3s;
            font-family: "League Gothic", sans-serif;
            font-size: 1.2em;
            border: none;
            color: white;
            width: 100%;
            border-radius: 7px;

            .salvar {
              background-color: #65a140;
              transition: background-color 0.3s;
              font-family: "League Gothic", sans-serif;
              font-size: 1em;
              border: none;
              color: white;
            }
          }

          .visualizar {
            text-align: center;
            background-color: #3e4095;
            text-decoration: none;
            font-family: "League Gothic", sans-serif;
            font-size: 1.2em;
            border: none;
            color: white;
            width: 100%;
            border-radius: 7px;
          }
        }
      }
    }
  }
`;
